@tailwind base;

@tailwind components;

@tailwind utilities;

html {
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

* {
  outline: none !important;
  font-family: "PaulGrotesk", sans-serif !important;
}

.verloop-button {
  display: none;
}
.poppins-family * {
  font-family: Poppins, sans-serif !important;
}
